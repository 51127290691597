import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const sectionConfig = {
transform(props) {
  const { noPaddingX, noPl, noPr, ...rest } = props;
  return {
    mx: "auto",
    pr: noPr || noPaddingX ? { base: "0" } : { base: "16px", md: "24px", lg: "42px", xl: 0 },
    pl: noPl || noPaddingX ? { base: "0" } : { base: "16px", md: "24px", lg: "42px", xl: 0 },
    maxW: { base: "100%", xl: 1180 },
    w: "full",
    ...rest
  };
},
defaultValues:{noPaddingX:false,noPr:false,noPl:false}}

export const getSectionStyle = (styles = {}) => {
  const _styles = getPatternStyles(sectionConfig, styles)
  return sectionConfig.transform(_styles, patternFns)
}

export const section = (styles) => css(getSectionStyle(styles))
section.raw = getSectionStyle