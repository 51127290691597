import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const containerConfig = {
transform() {
  return {
    alignItems: "center",
    display: "flex",
    flexDir: "column",
    justifyContent: "center",
    p: 0,
    mx: { base: "auto" },
    maxW: { base: "100%" }
  };
}}

export const getContainerStyle = (styles = {}) => {
  const _styles = getPatternStyles(containerConfig, styles)
  return containerConfig.transform(_styles, patternFns)
}

export const container = (styles) => css(getContainerStyle(styles))
container.raw = getContainerStyle